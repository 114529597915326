import { LOCALE } from "./Locale";

export const SETTINGS = {
  API_URL: process.env.REACT_APP_API_SERVER_URL,
  MAIL_SENDER_URL: process.env.REACT_APP_API_MAIL_SENDER_URL,
  API_AUTHORIZATION_HEADER: process.env.REACT_APP_API_AUTHORIZATION_HEADER,
  APP_NAME: process.env.REACT_APP_APP_NAME,
  RE_CAPTCHA_SITE_KEY: "6LcM0usoAAAAAMlSHUMkYyotXaDGXs4yYbqs4ayF",
  AUDIT_LOG_TYPES: [
    { name: "users_audit_log", value: "USERS_AUD" },
    { name: "groups_audit_log", value: "GROUPS_AUD" },
    { name: "system_settings_audit_log", value: "SYSTEM_SETTINGS_PROPS_AUD" },
  ],
  user_manual_ca:
    "/docs/еПазар - Корисничко упатство за Договорни органи_2023.pdf",
  user_manual_eo: "/docs/Корисничко упатство за Економски оператори_еПазар.pdf",
  tutorial_links:
    "https://www.youtube.com/watch?v=TQ8U66juQVU&list=PL7eSsAozIhgK__xf06z4G7DeYQoS_8mfT",
  rules_and_conditions: "/docs/ПРАВИЛА И УСЛОВИ ЗА КОРИСТЕЊЕ НА еПазар.pdf",
  marketplace_rules: "/docs/Правилник за е-пазар.pdf",
  attibutes_length: 3,
  product_description_length: 50,
  deactivation_reasons: [
    { id: 23, value: "attributes_and_measure_unit" },
    { id: 27, value: "measure_unit" },
    { id: 24, value: "attributes_and_product_type" },
    { id: 25, value: "product_type" },
    { id: 28, value: "invalid_price_reason" },
    { id: 26, value: "attributes" },
    { id: 29, value: "product_description" },
    { id: 32, value: "invalid_attribute" },
    { id: 20, value: "deactivate_product" },
  ],
  tenderStatusList: [
    { value: "all", name: "Сите" },
    { value: "Created", name: LOCALE.status_created },
    { value: "Published", name: LOCALE.status_published },
    { value: "ForEvaluation", name: LOCALE.status_for_evaluation },
    { value: "Evaluation", name: LOCALE.status_evaluation },
    { value: "ToBeSigned", name: LOCALE.status_to_be_signed },
    { value: "SignedByPresident", name: LOCALE.status_signed_by_president },
    { value: "ForApproval", name: LOCALE.status_for_approval },
    { value: "Cancel", name: LOCALE.status_cancel },
    { value: "ToBeCanceled", name: LOCALE.status_to_be_canceled },
    { value: "Completed", name: LOCALE.status_completed },
  ],
  appeal_document_type: [
    { documentTypeId: 1, value: "appeal_document" },
    { documentTypeId: 2, value: "payment_proof" },
    { documentTypeId: 3, value: "additional_appeal_documents" },
    { documentTypeId: 4, value: "appeal_response" },
    { documentTypeId: 5, value: "appeal_decision" },
    { documentTypeId: 6, value: "eo_request_for_additional_documents" },
    { documentTypeId: 7, value: "ca_request_for_additional_documents" },
  ],
  send_request_visible: true,
  attributes_validation_length: 2,
  has_signatures: true,
  create_tender_without_annual_plan: false,
  cancel_contract_deadline_days: 10,
  max_tender_budget_eur: 10000,
  max_image_size: 1 * 1024 * 1024,
  max_file_size: 20 * 1024 * 1024,
};
