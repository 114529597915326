import { COMMON_ACTIONS, countItems } from "../actions/CommonActions";

const initialState = {
  success: false,
  error: false,
  loading: false,
  mobileOpen: false,
  hideMenu: false,
  pageTitle: "",
  triggerRerender: false,
  apiConf: "",
  cardData: { cartItemId: 0, countCart: 0, cartItems: [], mainCategoryId: 0 },
  offerData: { offerItemSnapshotId: 0, offerItems: [] },
  importData: [],
  tenderDocuments: [],
  tenderData: { tenderSnapshotId: 0, tenderItems: {} },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case COMMON_ACTIONS.API_CALL_START:
      return {
        ...state,
        loading: true,
      };

    case COMMON_ACTIONS.API_CALL_FINISH:
      return {
        ...state,
        loading: false,
      };

    case COMMON_ACTIONS.SHOW_MESSAGE:
      return {
        ...state,
        message: action.payload.message,
        open: action.payload.open,
        variant: action.payload.variant,
      };

    case COMMON_ACTIONS.CLEAR_NOTIFICATIONS:
      return {
        ...state,
        open: false,
      };

    case COMMON_ACTIONS.MOBILE_SCREEN:
      return {
        ...state,
        mobileOpen: action.payload.mobileOpen,
      };

    case COMMON_ACTIONS.HIDE_MENU:
      return {
        ...state,
        hideMenu: action.payload.hideMenu,
      };

    case COMMON_ACTIONS.CHANGE_PAGE_TITLE:
      return {
        ...state,
        pageTitle: action.payload.pageTitle,
      };

    case COMMON_ACTIONS.RERENDER:
      return {
        ...state,
        triggerRerender: !state.triggerRerender,
      };
    case COMMON_ACTIONS.TENDER_DOCUMENTS:
      return {
        ...state,
        tenderDocuments: action.payload.tenderDocuments,
      };

    case COMMON_ACTIONS.API_CONFIG:
      return {
        ...state,
        apiConf: action.payload.links,
      };
    case COMMON_ACTIONS.LOAD_CART_FROM_STORAGE:
      return {
        ...state,
        cardData: {
          cartItemId: action.data.cartItemId,
          mainCategoryId: action.data.mainCategoryId,
          cartItems: JSON.parse(action.data.jsoncartItems),
          countCart: action.count,
        },
      };
    case COMMON_ACTIONS.ADD_TO_CART:
      return {
        ...state,
        cardData: {
          cartItemId: action.data.cartItemId,
          mainCategoryId: action.data.mainCategoryId,
          cartItems: JSON.parse(action.data.jsoncartItems),
          countCart: state.cardData.countCart + 1,
        },
      };
    case COMMON_ACTIONS.REMOVE_QUANTITY:
      return {
        ...state,
        cardData: {
          cartItemId: action.data.cartItemId,
          cartItems: JSON.parse(action.data.jsoncartItems),
          countCart: state.cardData.countCart - 1,
        },
      };
    case COMMON_ACTIONS.REMOVE_FROM_CART:
      return {
        ...state,
        cardData: {
          cartItemId: action.data.cartItemId,
          cartItems: JSON.parse(action.data.jsoncartItems),
          countCart: action.data.length,
          mainCategoryId: action.data.mainCategoryId,
        },
      };
    case COMMON_ACTIONS.CLEAN_CART:
      return {
        ...state,
        cardData: {
          countCart: 0,
          cartItems: [],
          mainCategoryId: 0,
        },
      };
    case COMMON_ACTIONS.ADD_TO_OFFER:
      return {
        ...state,
        offerData: {
          offerItemSnapshotId: action.data.offerItemSnapshotId,
          offerItems: JSON.parse(action.data.jsonofferItemSnapshots),
        },
      };
    case COMMON_ACTIONS.LOAD_OFFER:
      return {
        ...state,
        offerData: {
          offerItemSnapshotId: action.data.offerItemSnapshotId,
          offerItems: JSON.parse(action.data.jsonofferItemSnapshots),
        },
      };

    case COMMON_ACTIONS.IMPORT_DATA:
      return {
        ...state,
        importData: action.data,
      };
    case COMMON_ACTIONS.CLEAN_IMPORT_DATA:
      return {
        ...state,
        importData: [],
      };

    case COMMON_ACTIONS.CLEAN_OFFER_ITEM:
      return {
        ...state,
        offerData: {
          offerItems: [],
        },
      };
    case COMMON_ACTIONS.ADD_TENDER:
      return {
        ...state,
        tenderData: {
          tenderSnapshotId: action.data.tenderSnapshotId,
          tenderItems: JSON.parse(action.data.jsontenderSnapshot),
        },
      };
    case COMMON_ACTIONS.LOAD_TENDER:
      return {
        ...state,
        tenderData: {
          tenderSnapshotId: action.data.tenderSnapshotId,
          tenderItems: JSON.parse(action.data.jsontenderSnapshot),
        },
      };
    case COMMON_ACTIONS.CLEAN_TENDER:
      return {
        ...state,
        tenderData: {
          tenderItems: {},
        },
      };

    default:
      return state;
  }
}
