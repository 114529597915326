export const AuthService = {
  getToken: function () {
    var currentToken = window.localStorage.getItem("accessToken");
    if (currentToken) {
      return JSON.parse(currentToken);
    }
    return null;
  },
  setToken: function (tokenData) {
    if (tokenData) {
      window.localStorage.setItem(
        "accessToken",
        JSON.stringify(tokenData.token)
      );
      window.localStorage.setItem("currentUser", JSON.stringify(tokenData));
    }
  },
  updateAcceptedTermsAndConditions: function () {
    let user = JSON.parse(localStorage.getItem("currentUser"));
    user.hasAcceptedTermsAndConditions = true;
    localStorage.setItem("currentUser", JSON.stringify(user));
  },
  updateLocalAccessToken(token) {
    let user = JSON.parse(localStorage.getItem("currentUser"));
    user.Token = token;
    localStorage.setItem("accessToken", JSON.stringify(token));
  },
  getCurrentUser: function () {
    var currentUser = window.localStorage.getItem("currentUser");
    if (currentUser) {
      return JSON.parse(currentUser);
    }
    return null;
  },
  hasAnyRole: (roles) => {
    var currentUser = AuthService.getCurrentUser();
    if (currentUser?.roles?.filter((r) => roles?.includes(r)).length > 0) {
      return true;
    } else {
      return false;
    }
  },
};
