import React, { Suspense, lazy, useEffect } from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { createStore } from "redux";
import { Provider } from "react-redux";
import reducer from "./common/reducers/MainReducer";
import httpService from "./api/HttpClient";
import { Route, BrowserRouter, Redirect, useLocation } from "react-router-dom";
import { createBrowserHistory } from "history";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { SETTINGS } from "./properties/ApiProps";
//import ReactGA from "react-ga";
const MainComponent = lazy(() => import("./modules/public/MainComponent"));
const CatalogueComponent = lazy(() =>
  import("./modules/public/catalogue/CatalogueComponent")
);
const CatalogueProductComponent = lazy(() =>
  import("./modules/public/catalogue/CatalogueProductComponent")
);
const CatalogueProductDetailsComponent = lazy(() =>
  import("./modules/public/catalogue/CatalogueProductDetailsComponent")
);
const LoginComponent = lazy(() => import("./modules/login/LoginComponent"));
const App = lazy(() => import("./App"));
const ActiveTendersComponent = lazy(() =>
  import("./modules/public/tenders/ActiveTendersComponent")
);
const FinishedTendersComponent = lazy(() =>
  import("./modules/public/tenders/FinishedTendersComponent")
);
const PublicTenderDetailsComponent = lazy(() =>
  import("./modules/public/tenders/PublicTenderDetailsComponent")
);
const SignedContractsComponent = lazy(() =>
  import("./modules/public/signedContracts/SignedContractsComponent")
);
const UnauthorizedComponent = lazy(() =>
  import("./common/components/UnauthorizedComponent")
);

const theme = createMuiTheme({
  palette: {
    //type: "dark",
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#334b79",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: "#6E87B7",
      // dark: will be calculated from palette.secondary.main,
    },
    background: {
      appBar: "#000",
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  typography: {
    allVariants: {
      //color: "#505050",
      //  fontFamily: "'Open Sans', sans-serif",
      fontFamily: "'Montserrat', sans-serif",
    },
  },
});

const store = createStore(reducer);
httpService.setupInterceptors(store);
const history = createBrowserHistory({ forceRefresh: true });
// ReactGA.initialize("G-JMC9X6F8Z2");

// const PageTracking = (props) => {
//   const location = useLocation();
//   useEffect(() => {
//     ReactGA.pageview(location.pathname + location.search);
//   }, [location]);

//   return <></>;
// };

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <BrowserRouter history={history}>
        {/* <PageTracking /> */}
        <Suspense
          fallback={
            <Backdrop open={true}>
              <CircularProgress color="inherit" />
            </Backdrop>
          }
        >
          {/* ======================= PUBLIC ROUTES ======================= */}
          <Route path="/" exact component={MainComponent} />
          <Route path="/catalogue" exact component={CatalogueComponent} />
          <Route
            path="/catalogue/category/:categoryId"
            component={CatalogueProductComponent}
          />
          <Route
            path="/catalogue/category/:categoryId/details/:catalogProductId"
            component={CatalogueProductDetailsComponent}
          />
          {/* ======================= LOGIN/SECURITY ROUTES ======================= */}
          <GoogleReCaptchaProvider reCaptchaKey={SETTINGS.RE_CAPTCHA_SITE_KEY}>
            <Route path="/login" component={LoginComponent} />
          </GoogleReCaptchaProvider>

          <Route
            path="/app"
            render={() =>
              !localStorage.getItem("accessToken") &&
              !localStorage.getItem("currentUser") ? (
                <Redirect to="/login" />
              ) : (
                <App />
              )
            }
          />
          <Route
            path="/activeTenders"
            exact
            component={ActiveTendersComponent}
          />
          <Route
            path="/finishedTenders"
            exact
            component={FinishedTendersComponent}
          />
          <Route
            path="/signedContracts"
            exact
            component={SignedContractsComponent}
          />
          <Route
            path="/tender/:tenderId/details"
            exact
            component={PublicTenderDetailsComponent}
          />
          {/* ======================= ERRORS ROUTE ======================= */}
          {/* <Route exact path="/error/403" component={ForbidenComponent} /> */}
          <Route exact path="/error/403" component={UnauthorizedComponent} />
        </Suspense>
      </BrowserRouter>
    </Provider>
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
