export const COMMON_ACTIONS = {
  API_CALL_START: "API_CALL_START",
  API_CALL_FINISH: "API_CALL_FINISH",
  CLEAR_NOTIFICATIONS: "CLEAR_NOTIFICATIONS",
  SHOW_MESSAGE: "SHOW_MESSAGE",
  CLEAR_NOTIFY_MESSAGES: "CLEAR_NOTIFY_MESSAGES",
  MOBILE_SCREEN: "MOBILE_SCREEN",
  HIDE_MENU: "HIDE_MENU",
  CHANGE_PAGE_TITLE: "CHANGE_PAGE_TITLE",
  RERENDER: "RERENDER",
  API_CONFIG: "API_CONFIG",
  ADD_TO_CART: "ADD_TO_CART",
  REMOVE_QUANTITY: "REMOVE_QUANTITY",
  REMOVE_FROM_CART: "REMOVE_FROM_CART",
  CLEAN_CART: "CLEAN_CART",
  LOAD_CART_FROM_STORAGE: "LOAD_CART_FROM_STORAGE",
  ADD_TO_OFFER: "ADD_TO_OFFER",
  LOAD_OFFER: "LOAD_OFFER",
  CLEAN_OFFER_ITEM: "CLEAN_OFFER_ITEM",
  IMPORT_DATA: "IMPORT_DATA",
  CLEAN_IMPORT_DATA: "CLEAN_IMPORT_DATA",
  TENDER_DOCUMENTS: "TENDER_DOCUMENTS",
  ADD_TENDER: "ADD_TENDER",
  LOAD_TENDER: "LOAD_TENDER",
  CLEAN_TENDER: "CLEAN_TENDER",
};

export const notifyLoaderApiCallStart = () => {
  return { type: COMMON_ACTIONS.API_CALL_START };
};

export const notifyLoaderApiCallFinish = () => {
  return { type: COMMON_ACTIONS.API_CALL_FINISH };
};

export const notifyFormValidationFailedMessage = (validationResponse) => {
  if (validationResponse.valid === true) return;
  let errorMessage = "";
  validationResponse.reasons.forEach((reason) => {
    errorMessage += reason + "\r\n";
  });
  return {
    type: COMMON_ACTIONS.SHOW_MESSAGE,
    payload: {
      open: true,
      variant: "error",
      message: errorMessage,
    },
  };
};

export const notifyShowSuccessMessage = (message) => {
  return {
    type: COMMON_ACTIONS.SHOW_MESSAGE,
    payload: {
      open: true,
      variant: "success",
      message: message,
    },
  };
};

export const notifyShowErrorMessage = (message) => {
  return {
    type: COMMON_ACTIONS.SHOW_MESSAGE,
    payload: {
      open: true,
      variant: "error",
      message: message,
    },
  };
};

export const notifyShowWarningMessage = (message) => {
  return {
    type: COMMON_ACTIONS.SHOW_MESSAGE,
    payload: {
      open: true,
      variant: "warning",
      message: message,
    },
  };
};

export const notifyShowInfoMessage = (message) => {
  return {
    type: COMMON_ACTIONS.SHOW_MESSAGE,
    payload: {
      open: true,
      variant: "info",
      message: message,
    },
  };
};

export const showMobileScreen = (isMobile) => {
  return {
    type: COMMON_ACTIONS.MOBILE_SCREEN,
    payload: {
      mobileOpen: isMobile,
    },
  };
};

export const hideMenu = (isHide) => {
  return {
    type: COMMON_ACTIONS.HIDE_MENU,
    payload: {
      hideMenu: isHide,
    },
  };
};

export const changePageTitle = (pageTitle) => {
  return {
    type: COMMON_ACTIONS.CHANGE_PAGE_TITLE,
    payload: {
      pageTitle: pageTitle,
    },
  };
};

export const triggerRerender = () => {
  return {
    type: COMMON_ACTIONS.RERENDER,
    payload: {
      triggerRerender: true,
    },
  };
};

export const tenderDocuments = (data) => {
  return {
    type: COMMON_ACTIONS.TENDER_DOCUMENTS,
    payload: {
      tenderDocuments: data,
    },
  };
};

export const loadCart = (data, count) => {
  return {
    type: COMMON_ACTIONS.LOAD_CART_FROM_STORAGE,
    data: data,
    count: count,
  };
};

export const addToCart = (data) => {
  return {
    type: COMMON_ACTIONS.ADD_TO_CART,
    data: data,
  };
};

export const removeQuantity = (data) => {
  return {
    type: COMMON_ACTIONS.REMOVE_QUANTITY,
    data: data,
  };
};

export const addToOffer = (data) => {
  return {
    type: COMMON_ACTIONS.ADD_TO_OFFER,
    data: data,
  };
};

export const loadOffer = (data) => {
  return {
    type: COMMON_ACTIONS.LOAD_OFFER,
    data: data,
  };
};

export const cleanOfferItem = () => {
  return {
    type: COMMON_ACTIONS.CLEAN_OFFER_ITEM,
  };
};

export const removeFromCart = (data) => {
  return {
    type: COMMON_ACTIONS.REMOVE_FROM_CART,
    data: data,
  };
};

export const cleanCart = () => {
  return {
    type: COMMON_ACTIONS.CLEAN_CART,
  };
};

export const cleanImport = () => {
  return {
    type: COMMON_ACTIONS.CLEAN_IMPORT_DATA,
  };
};

export const importData = (data) => {
  return {
    type: COMMON_ACTIONS.IMPORT_DATA,
    data: data,
  };
};

export const addTender = (data) => {
  return {
    type: COMMON_ACTIONS.ADD_TENDER,
    data: data,
  };
};

export const loadTender = (data) => {
  return {
    type: COMMON_ACTIONS.LOAD_TENDER,
    data: data,
  };
};

export const cleanTender = () => {
  return {
    type: COMMON_ACTIONS.CLEAN_TENDER,
  };
};
